import React, { forwardRef } from 'react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'
import castArray from 'lodash.castarray'
import get from 'lodash.get'

export const commonStyles = (variant: 'outline' | 'solid') => {
  return `
    w-full appearance-none bg-transparent py-2 px-3 leading-tight outline outline-2
    ${variant === 'solid' ? 'outline-transparent placeholder:text-mid' : ''}
    ${variant === 'outline' ? 'outline-dark placeholder:text-light dark:outline-gray-300 dark:placeholder:text-gray-500 dark:bg-dark dark:text-white' : ''}
  `
}

type BaseInput = JSX.IntrinsicElements['input'] & JSX.IntrinsicElements['textarea']

// This component can work in both a controlled and uncontrolled manner.
//
// Pass in a 'name' prop to register the input with Remix Hook Form.
interface TextInputProps extends BaseInput {
  label?: string
  name?: string
  showValidIcon?: boolean
  showInvalidIcon?: boolean
  variant?: 'outline' | 'solid'
  error?: string | string[]
  /**
   * defaults to label if not specified
   */
  placeholder?: string
  /**
   * defaults to `text` if not specified
   */
  type?: React.HTMLInputTypeAttribute
  asTextarea?: boolean
  hint?: string
}

// Uncontrolled component
const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
  (
    {
      label,
      placeholder,
      type = 'text',
      name,
      showValidIcon = false,
      showInvalidIcon = false,
      error,
      variant = 'outline',
      asTextarea = false,
      hint,
      onChange,
      className,
      ...props
    },
    ref
  ) => {
    // This is a bit convoluted, but it's so we can still use this component even outside of react-hook-form
    const formContext = useFormContext()
    const register = formContext ? formContext.register : undefined
    const { errors: contextErrors = {}, dirtyFields = {} } = formContext?.formState || {}

    const integerClasses =
      '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'

    const common = {
      className: cx(
        commonStyles(variant),
        asTextarea ? 'rounded-2xl' : 'rounded-full',
        type === 'integer' ? integerClasses : '',
        !props.required &&
          'invalid:outline-transparent invalid:ring-1 invalid:ring-lightPink invalid:ring-offset-2 invalid:ring-offset-transparent',
        props.readOnly && 'focus:outline-transparent focus:ring-dark'
      ),
      // name,
      // type,
      placeholder: placeholder || label
    }

    const contextError = name ? (get(contextErrors, name)?.message as string | undefined) : undefined
    const wrapContextError = contextError ? castArray(contextError) : []
    const errors = (error ? castArray(error) : []).concat(wrapContextError)

    // Get register props including the ref from react-hook-form
    let registeredProps = {}
    if (name !== undefined && register) {
      // Get the registration props including the ref from react-hook-form
      registeredProps = register(name, { onChange })
    } else {
      registeredProps = { name, onChange }
    }

    // Combine refs if both register ref and forwarded ref exist
    const assignRefs = (node: HTMLInputElement | HTMLTextAreaElement | null) => {
      // Apply the ref from registeredProps if it exists (from react-hook-form)
      if (registeredProps && 'ref' in registeredProps) {
        const registerRef = (registeredProps as any).ref
        if (typeof registerRef === 'function') {
          registerRef(node)
        }
      }

      // Apply the forwarded ref
      if (ref) {
        if (typeof ref === 'function') {
          ref(node)
        } else {
          ;(ref as React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>).current = node
        }
      }
    }

    return (
      <div>
        <label className={cx('block space-y-1 text-sm font-bold', className)}>
          <div className="ml-3 flex items-center justify-between">
            <span className={cx(props.required && "after:ml-0.5 after:text-basePink after:content-['*']")}>
              {label}
            </span>
            {errors.map((error, index) => (
              <span key={index} className="font-sm font-normal text-basePink">
                {error}
              </span>
            ))}
          </div>
          <div className="relative">
            {asTextarea ? (
              <textarea
                rows={8}
                {...common}
                {...registeredProps}
                ref={assignRefs as React.Ref<HTMLTextAreaElement>}
                {...props}
              />
            ) : (
              <input
                {...common}
                {...registeredProps}
                ref={assignRefs as React.Ref<HTMLInputElement>}
                type={type === 'integer' ? 'number' : type}
                {...(type === 'integer' ? { step: '1' } : {})}
                {...props}
              />
            )}
            {showValidIcon && errors.length === 0 && name && dirtyFields?.[name] && (
              <CheckIcon className="absolute right-0 top-1/2 mr-2 h-5 w-5 -translate-y-1/2 text-baseBlue" />
            )}
            {showInvalidIcon && errors.length > 0 && name && dirtyFields?.[name] && (
              <XMarkIcon className="absolute right-0 top-1/2 mr-2 h-5 w-5 -translate-y-1/2 text-lightPink" />
            )}
          </div>
        </label>
        {hint && <p className="mx-3 mt-2 text-xs font-normal leading-4 text-mid dark:text-light">{hint}</p>}
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
